html,
body,
#root {
  box-sizing: border-box;
  color: white !important;
  margin: 0 !important;

  background-color: black;
}
a {
  text-decoration: none !important;
}
.font-atmospheric {
  font-family: atmospheric;
}
.content-header {
  font-family: atmospheric;

  font-weight: bolder;
  position: fixed !important;
  top: 5%;
  z-index: 1000;
}

.sc-papXJ,
.sc-jqUVSM {
  padding: 0 !important;
  overflow: auto !important ;
}

.sc-pyfCe.heybyG {
  overflow: auto !important;
}
.sc-pyfCe.crPWeG {
  overflow: auto !important;
}
.span-home {
  position: fixed;
}

/**/

:root {
  --app-bg: #171717;
  --app-text: hsl(0, 0%, 100%);
  --height: 10rem;
}

span.header {
  font-weight: 700;
  position: absolute;
  display: inline-block;
  width: 500px;
  transform: translate3d(0, -50%, 0);
  font-size: 9em;
  line-height: 0.9em;
  pointer-events: none;
  top: 350px;
  left: 50px;
}

span.header-left {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  position: absolute;
  display: inline-block;
  transform: translate3d(0, -50%, 0);
  line-height: 1em;
  top: 200px;
  left: 60px;
  font-size: 4em;
  width: 200px;
}

a {
  font-weight: 400;
  font-size: 14px;
  color: inherit;

  display: inline;
  text-decoration: none;
  z-index: 1;
  color: white;
}

a.top-left {
  top: 60px;
  left: 60px;
}

a.top-right {
  top: 60px;
  right: 60px;
}

a.bottom-left {
  bottom: 60px;
  left: 60px;
}

a.bottom-right {
  bottom: 60px;
  right: 60px;
}

/*scroll cuyston*/

/* ===== Scrollbar CSS ===== */
/* Firefox */

.calendly-none {
  display: none !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #18567c none;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #18567c;
  border-radius: 5px;
  border: 0px solid #ffffff;
}
